import { useContext } from "react";
import AboutUs from "../../components/HomeComponents/AboutUs/AboutUs";
import HeroImage from "../../components/HomeComponents/HeroImage/HeroImage";
import InstagramUpdates from "../../components/HomeComponents/InstagramUpdates/InstagramUpdates";
import Navbar from "../../components/ui/navbar/Navbar";
import ShowcaseItems from "../../components/HomeComponents/ShowcaseItems/ShowcaseItems";
import Options from "../../components/HomeComponents/Options/Options";
import WebplaceContext from "../../store/webplace-context";

const Home = () => {
  const instagramCtx = useContext(WebplaceContext).homeSocials.instagram_url;
  return (
    <div>
      <a href="#main" className="skip-to-main">Skip to main content</a>
      <Navbar />
      <div className="spacer"></div>
      <div id="main" aria-label="main-view">
        <HeroImage />
        <AboutUs id="main" />
        <ShowcaseItems />
        <Options />
        {instagramCtx.length > 0 && <InstagramUpdates />}
      </div>
    </div>
  );
};

export default Home;
